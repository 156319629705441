<template>
	<!-- container -->
	<div>
		<!-- photoWrap -->
		<div class="photoWrap v2">
			<!-- photoArea -->
			<div class="photoArea">
				<div class="photo">
					<img :src="imagePreview" alt="" />
				</div>
				<a @click="onImageDelete()" class="btnDel" v-if="imageId">이미지 삭제</a>
				<span class="btnPhoto" @change="onImageUpload"
					><input type="file" id="file" title="파일 업로드" accept="image/*"
				/></span>
			</div>
			<!--// photoArea -->
		</div>
		<!-- photoWrap -->
		<!-- contents -->
		<div class="contents">
			<!-- contCenter -->
			<div class="contCenter w360">
				<!-- inputList -->
				<div class="inputList">
					<dl class="row">
						<dt>닉네임</dt>
						<dd>
							<input
								type="text"
								title="닉네임 입력"
								placeholder="닉네임을 입력해주세요."
								v-model="nickname"
								readonly
								@click="onOpenNickname()"
								style="cursor:pointer;"
							/>
						</dd>
					</dl>
					<dl class="row">
						<dt>아이디</dt>
						<dd>
							<input
								type="text"
								title="아이디 입력"
								placeholder="아이디(이메일주소)를 입력해주세요."
								readonly
								v-model="loginId"
							/>
						</dd>
					</dl>
					<dl class="row">
						<dt>핸드폰번호</dt>
						<dd>
							<input
								type="text"
								title="핸드폰번호 입력"
								placeholder="핸드폰번호를 입력해주세요."
								v-model="mobileNo"
								readonly
								@click="onOpenMobileNo()"
								style="cursor:pointer;"
							/>
						</dd>
					</dl>
					<ul>
						<li v-if="this.type === 'N'"><a @click="onOpenPassword()">비밀번호 재설정</a></li>
						<li><a @click="manageFriend()">공유회원 관리</a></li>
						<li><a @click="manageMemo()">메모관리</a></li>
					</ul>
				</div>
				<!--// inputList -->
				<!-- btnArea -->
				<div class="btnArea">
					<button type="button" class="btn" @click="resignUser()">회원탈퇴</button>
				</div>
				<!--// btnArea -->
			</div>
			<!--// contCenter -->
		</div>
		<!--// contents -->
		<!-- 닉네임 팝업 start -->
		<div :class="layerNickname" id="layerNickname">
			<div class="inner" style="width:400px">
				<strong class="tit">닉네임 변경</strong>
				<div class="cont">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>닉네임 <em>*</em></dt>
							<dd class="flex">
								<input
									type="text"
									title="닉네임 입력"
									placeholder="닉네임을 입력해주세요."
									v-model="nickname2"
									maxlength="20"
								/>
								<button type="button" class="btn" @click="onNicknameDuplicate()">중복확인</button>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn btnGray" @click="onCloseNickname()">취소</button>
						<button type="button" class="btn" @click="onUpdateNickname()" :disabled="!changeNicknameBtn">확인</button>
					</div>
					<!--// btnArea -->
				</div>
				<a href="javascript:void(0)" name="layerNickname" @click="onCloseNickname()">닫기</a>
			</div>
		</div>
		<!-- 닉네임 팝업 end -->
		<!-- 비밀번호 팝업 start -->
		<div :class="layerPassword" id="layerPassword" v-if="this.type === 'N'">
			<div class="inner" style="width:400px">
				<strong class="tit">비밀번호 재설정</strong>
				<div class="cont">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>새로운 비밀번호 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="새로운 비밀번호 입력"
									placeholder="새로운 비밀번호(영문,숫자,특수문자 8~20자)"
									required="required"
									v-model="password"
									maxlength="20"
								/>
							</dd>
						</dl>
						<dl>
							<dt>새로운 비밀번호 확인 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="새로운 비밀번호 재입력"
									placeholder="새로운 비밀번호를 한번 더 입력해주세요."
									required="required"
									v-model="password2"
									maxlength="20"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn btnGray" @click="onClosePassword()">취소</button>
						<button type="button" class="btn" @click="onUpdatePassword()" :disabled="!changePasswordBtn">확인</button>
					</div>
					<!--// btnArea -->
				</div>
				<a href="javascript:void(0)" name="layerPassword" @click="onClosePassword()">닫기</a>
			</div>
		</div>
		<!-- 비밀번호 팝업 end -->
		<!-- 핸드폰번호 팝업 start -->
		<div :class="layerMobileNo" id="layerMobileNo">
			<div class="inner" style="width:400px">
				<strong class="tit">핸드폰 번호</strong>
				<div class="cont">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>핸드폰 번호 <em>*</em></dt>
							<dd>
								<input
									type="text"
									title="핸드폰 번호 입력"
									placeholder="'-' 없이 핸드폰 번호 입력해 주세요."
									required="required"
									v-model="mobileNo2"
									maxlength="11"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn btnGray" @click="onCloseMobileNo()">취소</button>
						<button type="button" class="btn" @click="onUpdateMobileNo()" :disabled="!changeMobileNoBtn">확인</button>
					</div>
					<!--// btnArea -->
				</div>
				<a href="javascript:void(0)" name="layerMobileNo" @click="onCloseMobileNo()">닫기</a>
			</div>
		</div>
		<!-- 핸드폰번호 팝업 end -->
	</div>
	<!--// container -->
</template>
<script>
import user from '@/api/user';
import file from '@/api/file';
import { showSpinner, hideSpinner, getAuth, isImage, setAuth, overFileSize } from '@/utils/cmm';
export default {
	created() {
		this.onGet();
	},
	data() {
		return {
			files: [],
			imageId: '',
			nickname: '',
			loginId: '',
			mobileNo: '',
			type: '',
			imagePreview: '',
			defaultPreview: '/resources/images/no-img.svg',
			layerNickname: 'layerNickname layerPop',
			nickname2: '',
			layerPassword: 'layerPassword layerPop',
			password: null,
			password2: null,
			layerMobileNo: 'layerMobileNo layerPop',
			mobileNo2: '',
			duplicateNickname: false,
		};
	},
	computed: {
		changeNicknameBtn() {
			return this.duplicateNickname == true;
		},
		changePasswordBtn() {
			return this.password && this.password2;
		},
		changeMobileNoBtn() {
			return this.mobileNo2.length > 0;
		},
	},
	watch: {
		nickname2: function() {
			this.duplicateNickname = false;
		},
	},
	methods: {
		async onGet() {
			let auth = getAuth();
			let res = await user.get(auth.id);
			this.imageId = res.imageId;
			this.nickname = res.nickname;
			this.loginId = res.loginId;
			this.mobileNo = res.mobileNo;
			this.type = res.type;
			this.imageId == null ? (this.imagePreview = this.defaultPreview) : (this.imagePreview = '/file/' + this.imageId);
		},
		async onImageUpload(e) {
			if (e.target.files[0] != undefined) {
				this.files = [];
				if (!isImage(e.target.files[0])) {
					document.querySelector('#file').value = '';
					this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
					return false;
				}
				if (overFileSize([], e.target.files[0], 0)) {
					document.querySelector('#file').value = '';
					return this.$alert(`이미지 파일의 용량은 100MB를 초과할 수 없습니다.`);
				}
				this.files.push(e.target.files[0]);
				this.imagePreview = URL.createObjectURL(this.files[0]);

				try {
					showSpinner();
					let res = await file.upload(this.files);
					let params = {
						imageId: res.data[0].id,
					};
					await user.update(params);
					if (this.imageId != null) {
						await file.remove(this.imageId);
					}
					await this.onGet();
				} catch (ex) {
					return await this.$alert(`${ex.message}`);
				} finally {
					hideSpinner();
				}
			}
			document.querySelector('#file').value = '';
		},
		async onImageDelete() {
			if (this.imageId != null) {
				let params = {
					imageId: 0,
				};
				try {
					showSpinner();
					await user.update(params);
					await file.remove(this.imageId);
					await this.onGet();
				} catch (ex) {
					return await this.$alert(`${ex.message}`);
				} finally {
					hideSpinner();
				}
			}
		},
		onOpenNickname() {
			this.layerNickname = 'layerNickname layerPop on';
		},
		onCloseNickname() {
			this.nickname2 = '';
			this.layerNickname = 'layerNickname layerPop';
		},
		onOpenPassword() {
			this.layerPassword = 'layerPassword layerPop on';
		},
		onClosePassword() {
			this.password = '';
			this.password2 = '';
			this.layerPassword = 'layerPassword layerPop';
		},
		onOpenMobileNo() {
			this.layerMobileNo = 'layerMobileNo layerPop on';
		},
		onCloseMobileNo() {
			this.mobileNo2 = '';
			this.layerMobileNo = 'layerMobileNo layerPop';
		},
		async onNicknameDuplicate() {
			try {
				showSpinner();
				await user.duplicateNickname(this.nickname2);
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '회원가입 안내');
			} finally {
				hideSpinner();
			}
			this.duplicateNickname = true;
			this.$alert(`사용할 수 있는 닉네임입니다.`, '회원가입 안내');
		},
		async onUpdateNickname() {
			if (!(await this.$confirm('닉네임을 변경하시겠습니까?', '닉네임 안내'))) return false;
			try {
				showSpinner();
				let params = {
					nickname: this.nickname2,
				};
				await user.update(params);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			let auth = getAuth();
			let res = await user.get(auth.id);
			let value = {
				id: res.id,
				loginId: res.loginId,
				nickname: res.nickname,
				imageId: res.imageId,
				mobileNo: res.mobileNo,
				type: res.type,
			};
			this.onCloseNickname();
			await this.$alert(`닉네임이 변경되었습니다.`, '닉네임 안내');
			await setAuth(value);
			this.nickname = res.nickname;
			this.nickname2 = '';
		},
		async onUpdatePassword() {
			try {
				showSpinner();
				await user.updatePassword(null, this.password, this.password2);
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '비밀번호 재설정 안내');
			} finally {
				hideSpinner();
			}
			this.onClosePassword();
			await this.$alert(`비밀번호가 재설정되었습니다.`, '비밀번호 재설정 안내');
			this.password = '';
			this.password2 = '';
		},
		async onUpdateMobileNo() {
			if (!(await this.$confirm('핸드폰번호를 변경하시겠습니까?', '핸드폰 번호 안내'))) return false;
			try {
				showSpinner();
				let params = {
					mobileNo: this.mobileNo2,
				};
				await user.update(params);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			let auth = getAuth();
			let res = await user.get(auth.id);
			let value = {
				id: res.id,
				loginId: res.loginId,
				nickname: res.nickname,
				imageId: res.imageId,
				mobileNo: res.mobileNo,
				type: res.type,
			};
			this.onCloseMobileNo();
			await this.$alert(`핸드폰번호가 변경되었습니다.`, '핸드폰 번호 안내');
			await setAuth(value);
			this.mobileNo = res.mobileNo;
			this.mobileNo2 = '';
		},
		manageFriend() {
			this.$router.push({ name: 'manageFriend' });
		},
		manageMemo() {
			this.$router.push({ name: 'manageMemo' });
		},
		resignUser() {
			this.$router.push({ name: 'resignUser' });
		},
	},
};
</script>

<style scoped>
.row dd input:read-only {
	background-color: white;
}
</style>
